import glass from "../../resources/glass.svg";
import azs from "../../resources/OswajamyAZS.png";
import patroni from "../../resources/patroni.png";
import katarzynaplatanazar from "../../resources//lecturers/katarzynaplatanazar.png";
import magdalenatrzeciak from "../../resources/lecturers/magdalenatrzeciak.png";
import sylwiabarsow from "../../resources/lecturers/sylwiabarsow.jpg";
import emiliamajsiak from "../../resources/lecturers/emiliamajsiak.png";
import edition3rd from "../../resources/edition3rd.svg";
export interface ImageRecord {
  id: number,
  url: string
}
export const IMAGES: ImageRecord[] = [
  {
    id: 0,
    url:
      glass
  },
  {
    id: 1,
    url:
      azs
  },
  {
    id: 2,
    url:
      patroni
  },
  {
    id: 3,
    url: magdalenatrzeciak
  },
  {
    id: 4,
    url: katarzynaplatanazar
  },
  {
    id: 6,
    url: sylwiabarsow
  },
  {
    id: 8,
    url: emiliamajsiak
  }
]
