import React from 'react'
import styles from './OAZS.module.scss'
import './event'
import gsap from 'gsap'
import scrollTo from 'gatsby-plugin-smoothscroll'
import { Power3, ScrollTrigger } from 'gsap/all'
import Helmet from 'react-helmet'
import Container from '../../components/Container/Container'
import azs from '../../resources/OswajamyAZS.png'
import { onBreakpoints } from '../../utils/Utils'
import { ImageRecord, IMAGES } from './eventImages'
import classNames from 'classnames'

interface State {
  loaded?: boolean
}

const EVENTBRITE_ID = 'eventbrite-widget-modal-trigger-903398086997'

class OAZS extends React.Component<never, State> {
  state: State = { loaded: false }

  glassRef = React.createRef()
  titleRef = React.createRef()
  barRef = React.createRef()

  componentDidMount() {
    ScrollTrigger.getAll().forEach(ST => ST.enable())
    gsap.registerPlugin(ScrollTrigger)
    ScrollTrigger.config({
      autoRefreshEvents: 'visibilitychange,DOMContentLoaded,load' // notice "resize" isn"t in the list
    })
    ;``
    const sections = document.getElementById('sections')

    let xPercent = (sections?.childElementCount || 1) * -100

    if (typeof window !== 'undefined' && window.innerWidth <= 768) {
      xPercent = ((sections?.childElementCount || 1) - 1) * -125 - 100
    }

    if (sections?.getBoundingClientRect().width !== sections?.children[0].getBoundingClientRect().width) {
      document.querySelectorAll(`.${styles.section}`).forEach(el => {
        el.setAttribute(
          'style',
          `min-width: ${sections?.getBoundingClientRect().width}px; max-width: ${sections?.getBoundingClientRect().width}px;`
        )
      })
    }

    gsap.to('#sections', {
      xPercent: xPercent,
      x: innerWidth,
      ease: 'none',
      scrollTrigger: {
        trigger: '#sections',
        start: 'top top',
        end: () => innerWidth * (sections?.childElementCount || 1),
        scrub: true,
        snap: 1 / ((sections?.childElementCount || 2) - 1),
        pin: true,
        anticipatePin: 1
      }
    })

    if (typeof document != 'undefined') {
      const button = document.getElementById('registrationButton')
      button?.classList.add(styles.show)
      ScrollTrigger.create({
        trigger: `#${EVENTBRITE_ID}`,
        start: 'top bottom',
        onEnter: () => button?.classList.add(styles.hide),
        onLeave: () => button?.classList.remove(styles.hide),
        onEnterBack: () => button?.classList.add(styles.hide),
        onLeaveBack: () => button?.classList.remove(styles.hide)
      })

      const loadImage = (image: ImageRecord) => {
        return new Promise((resolve, reject) => {
          const loadImg = new Image()
          loadImg.src = image.url
          loadImg.onload = () => resolve(image.url)
          loadImg.onerror = err => reject(err)
        })
      }

      Promise.all(IMAGES.map(image => loadImage(image)))
        .then(() =>
          this.setState({ loaded: true }, () => {
            onBreakpoints(
              () => {
                gsap
                  .from(this.barRef.current, {
                    scrollTrigger: this.barRef.current,
                    opacity: 0,
                    duration: 1,
                    x: -360,
                    ease: Power3.easeOut
                  })
                  .delay(1)
              },
              () => {
                gsap
                  .from(this.barRef.current, {
                    scrollTrigger: this.barRef.current,
                    opacity: 0,
                    duration: 1,
                    y: -360,
                    ease: Power3.easeOut
                  })
                  .delay(1)
              }
            )

            gsap.from(this.glassRef.current, {
              scrollTrigger: this.glassRef.current,
              opacity: 0,
              duration: 1,
              x: -360,
              ease: Power3.easeOut
            })
            gsap
              .from(this.titleRef.current, {
                scrollTrigger: this.titleRef.current,
                opacity: 0,
                duration: 1,
                x: 360,
                ease: Power3.easeOut
              })
              .delay(0.5)
          })
        )
        .catch(err => console.log('Failed to load images', err))
    }

    var exampleCallback = function() {
      console.log('Order complete!')
    }

    window.EBWidgets.createWidget({
      // Required
      widgetType: 'checkout',
      eventId: '903398086997',
      iframeContainerId: EVENTBRITE_ID,

      // Optional
      iframeContainerHeight: 500, // Widget height in pixels. Defaults to a minimum of 425px if not provided
      onOrderComplete: exampleCallback // Method called when an order has successfully completed
    })
  }

  componentWillUnmount() {
    ScrollTrigger.getAll().forEach(ST => ST.disable())
  }

  private renderPageHeader = () => (
    <div className={styles.eventPageWrapper}>
      {this.state.loaded && (
        <>
          <img ref={this.glassRef} className={styles.glass} src={IMAGES[0].url} alt={'oswajamy atopowe zapalenie skóry'} />
          <div ref={this.barRef} className={styles.bar} />
          <div ref={this.titleRef} className={styles.title}>
            <div>
              <span>OSWAJAMY</span>
              <span>ATOPOWE</span>
              <span>ZAPALENIE</span>
              <span>SKÓRY</span>
            </div>
          </div>
        </>
      )}
    </div>
  )

  private renderDescription = () => (
    <div className={styles.section}>
      <div>
        <div className={styles.description}>
          <h1>
            Oswajamy <span>Atopowe Zapalenie Skóry</span>
          </h1>

          <h3>
            Oswajamy Atopowe zapalenie skóry to seria wykładów skierowana do pacjentów oraz rodziców dzieci chorujących na atopowe zapalenie
            skóry, a także lekarzy, studentów i wszystkich osób pragnących poszerzyć swoją wiedzę w zakresie tej jednostki chorobowej.
          </h3>
        </div>
      </div>
    </div>
  )

  private renderFounder = () => (
    <div className={styles.section}>
      <div className={styles.founder}>
        <h3>Organizatorami akcji są</h3>
        <h2>
          <span>Dermatologiczne Studenckie Koło Naukowe</span> przy Katedrze i Klinice Dermatologii, Wenerologii i Alergologii Gdańskiego
          Uniwersytetu Medycznego
        </h2>
        <h3>wraz z opiekunem koła i promotorem akcji</h3>
        <h2>
          prof. dr hab. n. med. <span>Magdaleną Trzeciak</span>.
        </h2>
      </div>
    </div>
  )

  private renderTime = () => (
    <div className={styles.section}>
      <div className={styles.when}>
        <h1>
          Spotkanie rozpocznie się <span>5 czerwca 2024r. w środę, o godzinie 16:00</span> na platformie Microsoft Teams, a udział w nim jest
          bezpłatny. Link do wydarzenia zostanie wysłany na zarejestrowany adres email.
        </h1>
      </div>
    </div>
  )

  private renderSchedule = () => (
    <div className={styles.section}>
      <div>
        <div className={styles.schedule}>
          <h1 className={styles.scheduleTitle}>
            PROGRAM <span>SPOTKANIA</span>
          </h1>
          <ul>
            <li>
              <div className={styles.hours}>16:00 - 16:05</div>
              <div className={styles.subject}>
                <h4>Wstęp</h4>
                <h5>prof. dr hab. n. med. Magdalena Trzeciak</h5>
              </div>
            </li>
            <li>
              <div className={styles.hours}>16:05 - 16:20</div>
              <div className={styles.subject}>
                <h4>Atopowe Zapalenie Skóry - Pytania i odpowiedzi</h4>
                <h5>prof. dr hab. n. med. Magdalena Trzeciak</h5>
              </div>
            </li>
            <li>
              <div className={styles.hours}>16:20 - 16:30</div>
              <div className={styles.subject}>
                <h4>Emolienty w AZS - po co i jak je stosować?</h4>
                <h5>Studenci z SKN przy Katedrze i Klinice Dermatologii, Wenerologii i Alergologii GUMED</h5>
              </div>
            </li>
            <li>
              <div className={styles.hours}>16:30 - 16:50</div>
              <div className={styles.subject}>
                <h4>Alergia pokarmowa w AZS</h4>
                <h5>dr hab. n. med. Katarzyna Plata-Nazar</h5>
              </div>
            </li>
            <li>
              <div className={styles.hours}>16:50 - 17:10</div>
              <div className={styles.subject}>
                <h4>Co możemy zrobić, żeby pokarm przestał być alergenem?</h4>
                <h5>dr hab. n. med. Eliza Wasilewska</h5>
              </div>
            </li>
            <li>
              <div className={styles.hours}>17:10 - 17:20</div>
              <div className={styles.subject}>
                <h4>Sesja Q&A</h4>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )

  private renderLecturers = () => {
    const lecturers = [
      {
        img: IMAGES[3],
        name: 'prof. dr hab. n. med. Magdalena Trzeciak',
        desc:
          'Absolwentka Wydziału Lekarskiego Akademii Medycznej w Gdańsku, specjalista dermatolog- wenerolog (2009 r.), specjalista alergolog (2017 r.), wieloletni pracownik naukowo- dydaktyczny Katedry i Kliniki Dermatologii, Wenerologii i Alergologii Gdańskiego Uniwersytetu Medycznego. Doświadczenie zawodowe wzbogacone stażem zagranicznym w Klinice Dermatologii i Alergologii Uniwersytetu Ludwiga- Maximiliana w Monachium, w Niemczech. Od wielu lat pracuje z pacjentami chorującymi na atopowe zapalenie skóry. Współautorka polskich i międzynarodowych konsensusów terapeutycznych z zakresu atopowego zapalenia skóry, autorka ponad 200 publikacji w dziedzinie dermatologii i alergologii. Aktywny członek krajowych i międzynarodowych towarzystw naukowych m.in. Polskiego Towarzystwa Dermatologicznego, Polskiego Towarzystwa Alergologicznego, European Task Force on Atopic Dermatitis (ETFAD). W 2018 r. powołana do Zarządu International Society of Atopic Dermatitis (ISAD). '
      },
      {
        img: IMAGES[4],
        name: 'dr hab. n. med. Katarzyna Plata-Nazar',
        desc:
          'Pediatra, absolwentka Wydziału Lekarskiego Akademii Medycznej w Gdańsku (AMG, obecnie Gdański Uniwersytet Medyczny - GUMed). Od początku swojej kariery zawodowej związana z II Kliniką Chorób Dzieci AMG. Od roku 1996 do dnia dzisiejszego pracownik naukowo-dydaktyczny GUMed, na stanowisku adiunkta w Katedrze i Klinice Pediatrii, Gastroenterologii, Alergologii i Żywienia Dzieci. Posiada dwa stopnie specjalizacji z pediatrii (1997 r. i 2000 r.), jest też specjalistą w dziedzinie alergologii (2012 r.) oraz gastroenterologii dziecięcej (2017 r.). Od 2006 r. doktor nauk medycznych, a od 2015 r. - doktor habilitowany nauk medycznych. Jest członkiem Polskiego Towarzystwa Pediatrycznego, Polskiego Towarzystwa Alergologicznego, Polskiego Towarzystwa Gastroenterologii, Hepatologii i Żywienia Dzieci oraz The European Society for Paediatric Infectious Diseases, a także International Society of Pteridinology.'
      },
      {
        img: IMAGES[5],
        name: 'dr n. med. Sylwia Barsow',
        desc: ''
      },
      {
        img: IMAGES[6],
        name: 'dr n. med. Emilia Majsiak',
        desc: 'specjalistka diagnostyki molekularnej'
      }
    ]

    return (
      <div className={classNames(styles.section, styles.lecturers)}>
        <h1>
          Nasi <span>Prelegenci</span>
        </h1>
        <div className={styles.lecturersWrapper}>
          {lecturers.map(lecturer => (
            <div className={styles.lecturer}>
              <img alt={lecturer.name} src={lecturer.img.url} />
              <h4>{lecturer.name}</h4>
            </div>
          ))}
        </div>
      </div>
    )
  }
  private renderSections = () => (
    <div id={'sections'} className={styles.sectionWrapper}>
      {this.renderDescription()}
      {this.renderFounder()}
      {this.renderTime()}
      {this.renderSchedule()}
      {/* {this.renderLecturers()} */}
    </div>
  )

  private renderRegistrationButton = () => (
    <div
      id={'registrationButton'}
      onClick={() => {
        scrollTo(`#${EVENTBRITE_ID}`)
      }}
      className={styles.registrationButton}
    >
      Zarejestruj się
    </div>
  )

  render = () => (
    <div className={styles.oazs}>
      <Helmet
        title={'Wydarzenie - Oswajamy AZS'}
        meta={[
          { property: 'og:title', content: 'Wydarzenie - Oswajamy AZS' },
          { property: 'og:image', content: `https://atopowaskora.pl${azs}` },
          { property: 'og:image:alt', content: `https://atopowaskora.pl${azs}` },
          { property: 'image', content: `https://atopowaskora.pl${azs}` },
          { property: 'og:type', content: 'website' }
        ]}
      >
        <meta name="image" content={`https://atopowaskora.pl${azs}`} />
        <meta name="og:image" content={`https://atopowaskora.pl${azs}`} />
        <meta name="og:image:alt" content={`https://atopowaskora.pl${azs}`} />
      </Helmet>
      {this.renderRegistrationButton()}
      {this.renderPageHeader()}
      {this.renderSections()}
      <div className={styles.checkout} id={EVENTBRITE_ID} />
      {/*<div className={styles.checkoutTemp}><h2>Rejestracja rusza wkrótce!</h2></div>*/}
      <Container className={styles.patrons}>
        <h1>
          Nasi <span>Patroni:</span>
        </h1>
        <img src={IMAGES[2].url} />
      </Container>
    </div>
  )
}

export default OAZS
